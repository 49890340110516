<template>
  <div class="container home_page" v-loading="loading">
    <div class="pull-right" v-if="currentNode != {}">
      <div class="major-header p-20">
        <p class="title">{{ currentNode.name }}</p>
        <p class="desc m-t-15">国标代码{{ currentNode.id }}(不可用于填报)</p>
      </div>
      <div class="info m-t-20 m-b-20">
        <el-tabs type="card">
          <el-tab-pane label="专业概况">
            <div class="info-box m-t-30">
              <div class="info-item">
                <div class="el-image" style="width: 50px;height: 50px">
                  <img
                    src="../../assets/image/cengci.png"
                    style="object-fit: contain;"
                    class="el-image__inner"
                  />
                </div>
                <div class="info-text">
                  <div class="fz-18">{{ currentNode.eduLevel }}</div>
                  <div class="fz-14">学历层次</div>
                </div>
              </div>
              <div class="info-item">
                <div class="el-image" style="width: 50px;height: 50px">
                  <img
                    src="../../assets/image/shijian.png"
                    style="object-fit: contain;"
                    class="el-image__inner"
                  />
                </div>
                <div class="info-text">
                  <div class="fz-18">{{ currentNode.learnYearZh }}</div>
                  <div class="fz-14">修业年限</div>
                </div>
              </div>
              <div class="info-item">
                <div class="el-image" style="width: 50px;height: 50px">
                  <img
                    src="../../assets/image/xuewei.png"
                    style="object-fit: contain;"
                    class="el-image__inner"
                  />
                </div>
                <div class="info-text">
                  <div class="fz-18">{{ currentNode.degree }}</div>
                  <div class="fz-14">授予学位</div>
                </div>
              </div>
              <div class="info-item">
                <div class="el-image" style="width: 50px;height: 50px">
                  <img
                    src="../../assets/image/bili.png"
                    style="object-fit: contain;"
                    class="el-image__inner"
                  />
                </div>
                <div class="info-text">
                  <div class="fz-18">
                    {{ currentNode.maleRatio }}:{{ currentNode.femaleRatio }}
                  </div>
                  <div class="fz-14">男女比例</div>
                </div>
              </div>
            </div>
            <div class="major-info-list">
              <div
                class="major-info-content"
                v-if="
                  currentNode.introduction && currentNode.introduction != ''
                "
              >
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>专业介绍</span>
                  </span>
                </div>
                <p>{{ currentNode.introduction }}</p>
              </div>
              <div
                class="major-info-content"
                v-if="
                  currentNode.eduObjective && currentNode.eduObjective != ''
                "
              >
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>培养目标</span>
                  </span>
                </div>
                <p>{{ currentNode.eduObjective }}</p>
              </div>
              <div
                class="major-info-content"
                v-if="
                  currentNode.eduRequirement && currentNode.eduRequirement != ''
                "
              >
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>培养要求</span>
                  </span>
                </div>
                <p>{{ currentNode.eduRequirement }}</p>
              </div>
              <div
                class="major-info-content"
                v-if="
                  currentNode.subjectRequirement &&
                    currentNode.subjectRequirement != ''
                "
              >
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>学科要求</span>
                  </span>
                </div>
                <p>{{ currentNode.subjectRequirement }}</p>
              </div>
              <div
                class="major-info-content"
                v-if="
                  currentNode.loreAndAbility && currentNode.loreAndAbility != ''
                "
              >
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>知识能力</span>
                  </span>
                </div>
                <p>{{ currentNode.loreAndAbility }}</p>
              </div>
              <div
                class="major-info-content"
                v-if="
                  currentNode.studyDirection && currentNode.studyDirection != ''
                "
              >
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>考研方向</span>
                  </span>
                </div>
                <p>{{ currentNode.studyDirection }}</p>
              </div>
              <div
                class="major-info-content"
                v-if="currentNode.mainCourse && currentNode.mainCourse != ''"
              >
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>主要课程</span>
                  </span>
                </div>
                <p>{{ currentNode.mainCourse }}</p>
              </div>
              <div
                class="major-info-content"
                v-if="
                  currentNode.famousScholar && currentNode.famousScholar != ''
                "
              >
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>社会名人</span>
                  </span>
                </div>
                <p>{{ currentNode.famousScholar }}</p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="就业前景">
            <div class="m-t-30">
              <div
                class="major-info-content"
                v-if="
                  currentNode.jobDirection && currentNode.jobDirection != ''
                "
              >
                <div class="y-header-title">
                  <span style="font-size: 20px;font-weight: 500">
                    <span>就业方向</span>
                  </span>
                </div>
                <p>{{ currentNode.jobDirection }}</p>
              </div>
              <div class="major-info-content">
                <div v-if="XinZiPJ.length > 0">
                  <div class="y-header-title">
                    <span style="font-size: 20px;font-weight: 500">
                      <span>近10年平均薪资 </span>
                    </span>
                  </div>
                  <div class="salary-box">
                    <div
                      ref="salary"
                      id="salary"
                      class="chart-box m-r-30"
                      style="width: 360px; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;"
                    ></div>
                    <ul class="m-r-30">
                      <li v-for="(item, i) in XinZiPJ.slice(0, 5)">
                        <div class="m-r-20">{{ i + 1 }}</div>
                        <div style="width: 280px">{{ item.name }}</div>
                        <div class="value">
                          {{
                            item.value > 1000
                              ? (item.value / 1000).toFixed(2) + 'K'
                              : item.value
                          }}
                        </div>
                      </li>
                    </ul>
                    <ul class="m-r-30">
                      <li v-for="(item, i) in XinZiPJ.slice(5, 10)">
                        <div class="m-r-20">{{ i + 6 }}</div>
                        <div style="width: 100%">{{ item.name }}</div>
                        <div class="value">
                          {{
                            item.value > 1000
                              ? (item.value / 1000).toFixed(2) + 'K'
                              : item.value
                          }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="future m-t-30">
                  <div class="mainJobs" v-if="ZhiYeFX.length > 0">
                    <div class="y-header-title">
                      <span style="font-size: 20px;font-weight: 500">
                        <span>主要职业分布</span>
                      </span>
                    </div>
                    <div
                      id="job"
                      ref="job"
                      style="width:360px;margin:0 auto;height:234px;-webkit-tap-highlight-color: transparent; user-select: none;"
                    ></div>
                    <el-collapse accordion>
                      <el-collapse-item
                        :title="item.name"
                        :name="i"
                        v-for="(item, i) in ZhiYeFX"
                      >
                        <template slot="title">
                          <div class="value">{{ item.value }}%</div>
                          <span
                            class="text-overflow-ellipsis"
                            style="width: 100%;"
                            >{{ item.name }}</span
                          >
                        </template>
                        <div>具体岗位：{{ item.expands }}</div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                  <div class="mainJobs" v-if="HangYeFB.length > 0">
                    <div class="y-header-title">
                      <span style="font-size: 20px;font-weight: 500">
                        <span>主要行业分布</span>
                      </span>
                    </div>
                    <div
                      id="industry"
                      ref="industry"
                      style="width:360px;margin:0 auto;height:234px;-webkit-tap-highlight-color: transparent; user-select: none;"
                    ></div>
                    <div
                      class="el-collapse-item__header"
                      v-for="(item, i) in HangYeFB"
                    >
                      <div class="value">{{ item.value }}%</div>
                      <span class="text-overflow-ellipsis" style="width: 100%;"
                        ><span class="m-r-20">{{ i + 1 }}</span>
                        {{ item.name }}</span
                      >
                    </div>
                  </div>
                  <div class="mainJobs" v-if="ChengShiFB.length > 0">
                    <div class="y-header-title">
                      <span style="font-size: 20px;font-weight: 500">
                        <span>主要就业地区分布</span>
                      </span>
                    </div>
                    <div
                      id="region"
                      ref="region"
                      style="width:360px;margin:0 auto;height:234px;-webkit-tap-highlight-color: transparent; user-select: none;"
                    ></div>
                    <div
                      class="el-collapse-item__header"
                      v-for="(item, i) in ChengShiFB"
                    >
                      <div class="value">{{ item.value }}%</div>
                      <span class="text-overflow-ellipsis" style="width: 100%;"
                        ><span class="m-r-20">{{ i + 1 }}</span>
                        {{ item.name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="padding-bottom: 80px"
                class="m-t-30"
                v-if="description.length > 0"
              >
                <p class="fz-14" style="color: #333">
                  数据说明:
                </p>
                <p
                  class="fz-12"
                  style="color: #666"
                  v-for="(item, i) in description"
                >
                  {{ i + 1 }}、{{ item }}
                </p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="开设院校" v-if="collegeList.length > 0">
            <div class="filter-list" v-for="(item, i) in filterOption">
              <span class="filter-list-title">{{ item.name }}</span>
              <div class="filter-list-tags">
                <span
                  class="tag"
                  :class="filterData[item.type].length === 0 ? 'active' : ''"
                  @click="filterClick(i, -1)"
                  >不限</span
                >
                <span
                  class="tag"
                  v-for="(tag, index) in item.list"
                  :class="tag.isChecked ? 'active' : ''"
                  @click="filterClick(i, index)"
                  >{{ tag.name }}</span
                >
              </div>
            </div>
            <div class="content">
              <div class="left m-r-30 m-t-30">
                <div class="search-college">
                  <span data-v-4ab00b20="" class="total">
                    共
                    <span data-v-4ab00b20="" class="total-num">{{
                      total
                    }}</span>
                    条
                  </span>
                  <div style="">
                    <el-input
                      style="width: 100%"
                      class="inline-input"
                      v-model="filterData.keyword"
                      prefix-icon="el-icon-search"
                      placeholder="搜索"
                    >
                      <el-button slot="append" type="primary" @click="search"
                        >搜索</el-button
                      >
                    </el-input>
                  </div>
                </div>
                <div class="no-data" v-if="collegeList.length === 0">
                  <img src="../../assets/image/nodata.png" />
                  <p class="text m-t-30">暂无相关结果</p>
                </div>
                <div v-else style="height: auto;overflow:auto">
                  <ul class="list">
                    <li
                      v-for="(item, i) in collegeList"
                      class="college-list m-b-30"
                    >
                      <div>
                        <img
                          :src="item.logo"
                          style="width: 72px; height: 72px;"
                        />
                      </div>
                      <div class="m-l-16" style="width: 310px">
                        <div class="college-name" @click="viewDetail(item)">
                          {{ item.name }}
                        </div>
                        <el-tag
                          v-for="tag in item.features"
                          :key="tag"
                          type="info"
                          effect="plain"
                        >
                          {{ tag }}
                        </el-tag>
                        <div class="college-desc">
                          {{ item.cityName }} /
                          {{ item.categories.toString() }} /
                          {{ item.natureType }} /
                          {{ item.belong }}
                        </div>
                      </div>
                      <div class="heat fz-12">
                        排名<br />第{{ i + 1 }}名 / 综合指数{{ item.comScore }}
                      </div>
                      <div class="heat fz-12">
                        热度<br />{{
                          item.hits <= 10000
                            ? item.hits
                            : (item.hits / 10000).toFixed(1) + '万'
                        }}
                      </div>
                    </li>
                  </ul>
                  <!--                  <p class="loading" v-if="loading">加载中...</p>-->
                  <p class="loading" v-if="noMore">以上为全部</p>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      majorBenList: [],
      majorZhuanList: [],
      activeName: '本科',
      params: {},
      currentNode: {},
      loading: true,
      salary: null,
      industry: null,
      job: null,
      region: null,
      XinZiPJ: [],
      HangYeFB: [],
      ZhiYeFX: [],
      ChengShiFB: [],
      description: [],
      filterOption: [],
      filterData: {
        provinces: [],
        features: [],
        categories: [],
        keyword: '',
        majorCode: '',
      },
      count: 10,
      total: 0,
      collegeList: [],
    };
  },
  mounted: async function() {
    let res = await this.$axios.get('/php/major.list');
    this.majorBenList = res.data.data.ben.map((e) => {
      e.children = e.major;
      return e;
    });
    this.majorZhuanList = res.data.data.zhuan.map((e) => {
      e.children = e.major;
      return e;
    });
    const { path, query } = this.$router.currentRoute;
    this.params = query;
    this.filterData.majorCode = this.params.id;
    this.activeName = this.params.type;
    await this.setData();
    const college = await this.$axios.get('/php/college.list.config');
    college.data.data.map((e, i) => {
      e.list.map((a) => {
        a.isChecked = false;
        return a;
      });
      switch (i) {
        case 0:
          e.type = 'provinces';
          break;
        case 1:
          e.type = 'categories';
          break;
        case 2:
          e.type = 'features';
          break;
      }
      return e;
    });
    this.filterOption = college.data.data;
    await this.getCollegeList();
    window.addEventListener('scroll', this.windowScroll);
  },
  computed: {
    noMore() {
      return this.collegeList.length >= this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.windowScroll); //销毁滚动事件
  },
  methods: {
    changeMajor(id) {
      this.$router.push({
        path: '/majors/middle?id=' + id + '&type=' + this.activeName,
      });
    },
    filterClick(firstIndex, secondIndex) {
      let type = '';
      switch (firstIndex) {
        case 0:
          type = 'provinces';
          break;
        case 1:
          type = 'categories';
          break;
        case 2:
          type = 'features';
          break;
      }
      if (secondIndex === -1) {
        // 选择不限清除所有筛选条件
        this.filterData[type] = [];
        this.filterOption[firstIndex].list = this.filterOption[
          firstIndex
        ].list.map((e) => {
          e.isChecked = false;
          return e;
        });
        this.total = 0;
        this.collegeList = [];
        this.getCollegeList();
        return false;
      }
      const data = this.filterOption[firstIndex].list[secondIndex];
      if (data.isChecked) {
        // 已选择需在filterData中删除
        this.filterData[type] = _.without(this.filterData[type], data.value);
      } else {
        // 未选择在filterData中插入
        this.filterData[type].push(data.value);
      }
      this.loading = true;
      this.total = 0;
      this.collegeList = [];
      this.getCollegeList();
      data.isChecked = !data.isChecked;
      console.log(firstIndex, secondIndex);
    },
    search() {
      this.total = 0;
      this.collegeList = [];
      this.getCollegeList();
    },
    async getCollegeList() {
      let reqData = {
        ...this.filterData,
      };
      if (this.collegeList.length > 0) {
        reqData.sort = this.collegeList[this.collegeList.length - 1].sort;
      }
      const res = await this.$axios.post('/php/college.list', reqData);
      this.collegeList = this.collegeList.concat(res.data.data.list);
      this.total = res.data.data.total;
      this.loading = false;
      console.log(JSON.stringify(res.data.data.list));
    },
    async load() {
      this.loading = true;
      await this.getCollegeList();
    },
    viewDetail(data) {
      const param = {
        id: data.id,
        name: data.name,
      };

      // window.open('/college/detail?' + getParams(param));
    },
    changeChildrenMajor(data) {
      this.loading = true;
      this.params = {
        id: data.id,
        parentId: data.parentCode,
        type: this.activeName,
      };
      this.activeName = this.params.type;
      this.setData();
    },
    async setData() {
      let currentNode;
      const res = await this.$axios.get(
        '/php/major.detail?majorCode=' + this.params.id
      );
      this.currentNode = res.data.data.detail;
      let XinZiPJ = res.data.data.majorJobFuture.filter((e) => e.type == 'XinZiPJ');
      this.XinZiPJ = _.reverse(XinZiPJ.length > 0 ? XinZiPJ[0].futures : []);
      let HangYeFB = res.data.data.majorJobFuture.filter(
        (e) => e.type == 'HangYeFB'
      );
      this.HangYeFB = HangYeFB.length > 0 ? HangYeFB[0].futures : [];
      let ZhiYeFX = res.data.data.majorJobFuture.filter((e) => e.type == 'ZhiYeFX');
      this.ZhiYeFX = ZhiYeFX.length > 0 ? ZhiYeFX[0].futures : [];
      let ChengShiFB = res.data.data.majorJobFuture.filter(
        (e) => e.type == 'ChengShiFB'
      );
      this.description = ChengShiFB.length > 0 ? ChengShiFB[0].description : '';
      this.ChengShiFB = ChengShiFB.length > 0 ? ChengShiFB[0].futures : [];
      await this.setChat();
      this.loading = false;
    },
    async setChat() {
      this.$nextTick(async () => {
        let salary = this.$refs['salary'];
        let job = this.$refs['job'];
        let industry = this.$refs['industry'];
        let region = this.$refs['region'];
        this.salary = this.$echarts.init(salary, 'light');
        this.job = this.$echarts.init(job, 'light');
        this.industry = this.$echarts.init(industry, 'light');
        this.region = this.$echarts.init(region, 'light');
        let salaryOption = {
          tooltip: [
            {
              trigger: 'axis',
              zlevel: 0,
              z: 60,
              show: true,
              showContent: true,
              triggerOn: 'mousemove|click',
              alwaysShowContent: false,
              displayMode: 'single',
              renderMode: 'auto',
              confine: false,
              showDelay: 0,
              hideDelay: 100,
              transitionDuration: 0.4,
              enterable: false,
              backgroundColor: 'rgba(50,50,50,0.7)',
              borderColor: '#333',
              borderRadius: 4,
              borderWidth: 0,
              padding: 5,
              extraCssText: '',
              axisPointer: {
                type: 'line',
                axis: 'auto',
                animation: 'auto',
                animationDurationUpdate: 200,
                animationEasingUpdate: 'exponentialOut',
                crossStyle: {
                  color: '#999',
                  width: 1,
                  type: 'dashed',
                  textStyle: {},
                },
              },
              textStyle: {
                color: '#fff',
                fontSize: 14,
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              axisTick: false,
              boundaryGap: [0, 0],
              splitNumber: 5,
              minorTick: {
                show: false,
                splitNumber: 5,
                length: 3,
                lineStyle: {},
              },
              minorSplitLine: {
                show: false,
                lineStyle: {
                  color: '#eee',
                  width: 1,
                },
              },
              show: true,
              zlevel: 0,
              z: 0,
              inverse: false,
              name: '',
              nameLocation: 'end',
              nameRotate: null,
              nameTruncate: {
                maxWidth: null,
                ellipsis: '...',
                placeholder: '.',
              },
              nameTextStyle: {},
              nameGap: 15,
              silent: false,
              triggerEvent: false,
              tooltip: {
                show: false,
              },
              axisPointer: {},
              axisLine: {
                show: true,
                onZero: true,
                onZeroAxisIndex: null,
                lineStyle: {
                  color: '#333',
                  width: 1,
                  type: 'solid',
                },
                symbol: ['none', 'none'],
                symbolSize: [10, 15],
              },
              axisLabel: {
                show: true,
                inside: false,
                rotate: 0,
                showMinLabel: null,
                showMaxLabel: null,
                margin: 8,
                fontSize: 12,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: ['#ccc'],
                  width: 1,
                  type: 'solid',
                },
              },
              splitArea: {
                show: false,
                areaStyle: {
                  color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
                },
              },
              offset: 0,
              rangeEnd: null,
              rangeStart: null,
            },
          ],
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: _.reverse(this.XinZiPJ.map((e) => e.name)),
            },
          ],
          grid: [
            {
              top: '3%',
              left: 0,
              right: '4%',
              bottom: '3%',
              containLabel: true,
              show: true,
              zlevel: 0,
              z: 0,
              backgroundColor: 'rgba(0,0,0,0)',
              borderWidth: 1,
              borderColor: '#ccc',
            },
          ],
          series: [
            {
              data: _.reverse(this.XinZiPJ.map((e) => e.value)),
              type: 'line',
              label: {
                position: 'top',
              },
              lineStyle: {
                width: 2,
                type: 'solid',
              },
            },
          ],
        };
        this.salary.setOption(salaryOption);
        let jobOption = {
          series: [
            {
              type: 'pie',
              radius: ['30%', '60%'],
              data: this.ZhiYeFX.map(({ name, value }) => {
                return { name, value };
              }),
            },
          ],
        };
        this.job.setOption(jobOption);
        let industryOption = {
          series: [
            {
              type: 'pie',
              radius: ['30%', '60%'],
              data: this.HangYeFB.map(({ name, value }) => {
                return { name, value };
              }),
            },
          ],
        };
        this.industry.setOption(industryOption);
        let regionOption = {
          series: [
            {
              type: 'pie',
              radius: ['30%', '60%'],
              data: this.ChengShiFB.map(({ name, value }) => {
                return { name, value };
              }),
            },
          ],
        };
        this.region.setOption(regionOption);
      });
    },
    //获取当前可视范围的高度
    getClientHeight() {
      var clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = Math.min(
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
      } else {
        clientHeight = Math.max(
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
      }
      return clientHeight;
    },

    //获取文档完整的高度
    getScrollHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      );
    },

    //获取当前滚动条的位置
    getScrollTop() {
      var scrollTop = 0;
      //window.pageYOffset = document.documentElement.scrollTop
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      return scrollTop;
    },
    // 防抖函数
    debounce(fn, delay) {
      let timer = null;
      return function() {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function() {
          console.log('防抖');
          fn.apply(context, args);
        }, delay);
      };
    },
    //回调函数
    async windowScroll() {
      //获取三个值
      var scrollTop = this.getScrollTop();
      var clientHeight = this.getClientHeight();
      var scrollHeight = this.getScrollHeight();
      //如果满足公式则，确实到底了
      console.log(scrollTop + clientHeight, scrollHeight);
      if (scrollTop + clientHeight >= scrollHeight - 8) {
        //发送异步请求请求数据，同时携带offset并自增offset
        //noMore是自定义变量，如果是最后一批数据则以后都不加载
        console.log('滚动到底部');
        if (!this.noMore) {
          this.getCollegeList();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.container {
  overflow: hidden;
        // background: #fff;

  .text-primary {
    color: #910000 !important;
  }
.el-input--prefix .el-input__inner{
  height: 40px !important;
  line-height: 40px !important;
}
  .pull-left {
    float: left;
    width: 260px;
    margin-right: 40px;
    margin-bottom: 20px;

    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      color: #910000;
      background-color: #fff;
      border-right-color: #dcdfe6;
      border-left-color: #dcdfe6;
    }

    .custom-tree-node {
      flex: 1;
      padding-right: 10px;
    }
  }

  .pull-right {
    // width: 900px;
    // float: right;

    .major-header {
      height: 114px;
      position: relative;
      background: rgba(233, 48, 45, 0.08) !important;

      .title {
        color: #333;
        line-height: 40px;
        font-size: 28px;
        font-weight: 500;
      }

      .desc {
        color: #999;
        line-height: 20px;
        font-size: 14px;
      }
    }

    .info {
      a[href] {
        color: #333;
      }

      .y-header-title {
        border-left: 3px solid #910000;
        padding-left: 10px;
        margin-bottom: 30px;
      }

      .major-item {
        padding: 15px 0 10px;
        border-bottom: 1px solid #f2f2f2;

        .label {
          color: #333;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .label:hover {
          color: #910000;
        }

        .desc {
          color: #999;
        }
      }

      .el-tabs--card > .el-tabs__header {
        border-bottom: 1px solid #910000;
        margin: 0 0;
        padding:0 15px;
      }

      .el-tabs__item.is-active {
        color: #fff;
      }

      .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
        background: #910000;
        border-radius: 4px 4px 0 0;
        color: #fff !important;
      }

      .el-tabs--card > .el-tabs__header .el-tabs__item {
        border: none;
        font-size: 16px;
        // padding: 0 33px !important;
      }

      .el-tabs--card > .el-tabs__header .el-tabs__nav {
        border: none;
      }
      .el-tab-pane{
        padding: 0 15px;
        background: #fff;
      }

      .info-box {
        // width: 780px;
        margin-bottom: 43px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        .info-item {
          width: 195px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
          -webkit-box-orient: horizontal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
        }

        .info-text {
          -webkit-box-orient: vertical;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-left: 10px;
        }

        .info-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
        }
      }

      .major-info-list {
        // width: 860px;
        // margin-right: 40px;
      }
    }

    .major-info-content {
      margin-bottom: 43px;

      p {
        font-size: 14px;
        color: #666;
        line-height: 22px;
      }

      .salary-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        li {
          width: 240px;
          height: 44px;
          line-height: 44px;
          font-size: 16px;
          color: #333;
          border-bottom: 1px solid #f2f2f2;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          .value {
            color: #910000;
            width: 50px;
            display: inline-block;
            text-align: right;
          }
        }
      }

      .future {
        margin-bottom: 43px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;

        .mainJobs {
          // width: 280px;
          // margin-right: 20px;
          width: 100%;

          .el-collapse-item__header {
            // width: 280px;
            height: 44px;
            line-height: 44px;
            font-size: 16px;
            color: #333;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            margin-right: 10px;
          }
        }

        .value {
          color: #910000;
        }
      }
    }

    .chart-box {
      // width: 280px;
      height: 234px;
    }
  }
}

.el-tooltip__popper .popper__arrow {
  border: none;
}

.tooltip {
  width: 350px;
  padding: 30px;
  border: none !important;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .small {
    cursor: pointer;
    margin-bottom: 10px;
    color: #666;
    float: left;
  }

  .small:hover {
    color: #910000;
  }
}

a:hover {
  color: #910000;
}

.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filter-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;

  .filter-list-title {
    font-size: 14px;
    text-align: center;
    color: #9b9b9b;
    margin-top: 13px;
  }

  .filter-list-tags {
    margin-left: 10px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .tag {
      color: #212121;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      cursor: pointer;
      margin: 10px;
      padding: 2px 6px;
      text-align: center;
    }

    .active {
      color: #910000;
      border: 1px solid #910000;
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;

  .el-tag {
    height: 16px;
    line-height: 14px;
    font-size: 12px;
    margin-right: 10px;
    border-radius: 4px;
    padding: 0 2px;
  }

  .el-input-group__append {
    background-color: #910000;
    color: #fff;
    border-radius: 0 4px 4px 0;
    border: none;
  }

  .college-name {
    margin-bottom: 6px;
    font-size: 20px;
    color: #333;
    cursor: pointer;
  }

  .college-name:hover {
    color: #910000;
  }

  .left {
    width: 860px;
    height: auto;

    .search-college {
      width: 860px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 40px;

      .total {
        display: inline-block;
        margin-right: 10px;
        width: 72px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #666;
      }

      .total-num {
        color: #910000;
      }
    }

    .college-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
    }

    .college-desc {
      font-size: 14px;
      color: #666;
      margin-top: 6px;
    }

    .heat {
      width: 200px;
      height: 72px;
      padding-top: 22px;
      color: #666;
    }
  }
}

.no-data {
  width: 300px;
  height: 540px;
  margin: 150px auto 0;

  img {
    margin: 0 auto;
    display: block;
    width: 134px;
    height: 118px;
  }

  .text {
    text-align: center;
    color: #666666;
  }
}

.loading {
  text-align: center;
  font-size: 14px;
  color: #999999;
}
</style>
